var Admin = function() {

    var dataTableSort = function() {
        $('th.sorting').on('click', function() {
            window.location = $(this).find('a').prop('href');
        });
    };

    var confirmDelete = function() {
        var $form;

        $('button.delete-confirmation').on('click', function(e) {
            $('#modal-delete-entity').text($(this).data('name'));
            $('#delete-modal').modal('show');
            $form = $(this).parent();

            e.preventDefault();
        });

        $('#delete-modal').on('click', 'button#confirm-delete', function(e) {
            $form.submit();
            e.preventDefault();
        });
    };

    var cubeLightbox = function() {
        var $lightBoxWrapper = $('.cube-lightbox-wrapper');

        if ($lightBoxWrapper.length) {
            $lightBoxWrapper.cubeportfolio({
                caption: 'overlayBottomReveal',
                //displayType: 'sequentially',
                displayTypeSpeed: 1,
                lightboxDelegate: '.cbp-lightbox',
                lightboxGallery: true,
                lightboxTitleSrc: 'data-title',
                lightboxCounter: '<div class="cbp-popup-lightbox-counter">{{current}} of {{total}}</div>',
            });
        }
    };

    var copyToClipboard = function () {
        new Clipboard('.copy-to-clipboard');
    };

    var initFancybox = function () {
        $('.fancybox').fancybox({

        });
    };

    var initWysiHtml = function()
    {
        $('textarea.wysihtml').wysihtml5();
    };

    var initSortTable = function () {
        var request;
        $('#sortable').sortable({
            axis: 'y',
            update: function (event, ui) {
                var data = $(this).sortable('serialize');
                // POST to server using $.post or $.ajax
                request = $.ajax({
                    data: data,
                    type: 'POST',
                    url: window.location.pathname
                });

                request.done(function (response, textStatus, jqXHR){
                    // Log a message to the console
                    console.log(response);
                });
            }
        });
    };

    var initSelect2 = function()
    {
        $('.select2').select2({
            tags: true,
            multiple: true,
            tokenSeparators: [',', ' ']
        });
    };

    var initSelect2Plain = function()
    {
        $('.select2plain').select2();
    };

    return {
        init: function() {
            dataTableSort();
            confirmDelete();
            cubeLightbox();
            copyToClipboard();
            initFancybox();
            initWysiHtml();
            initSortTable();
            initSelect2();
            initSelect2Plain();
        }
    };

}();

jQuery(document).ready(function() {
    Admin.init();
});